import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'jquery', src: '../../assets/web/assets/jquery/jquery.min.js' },
  { name: 'popper', src: '../../assets/popper/popper.min.js' },
  { name: 'tether', src: '../../assets/tether/tether.min.js' },
  { name: 'bootstrap', src: '../../assets/bootstrap/js/bootstrap.min.js' },
  { name: 'jquery.viewportchecker', src: '../../assets/viewportchecker/jquery.viewportchecker.js' },
  { name: 'smooth-scroll', src: '../../assets/smoothscroll/smooth-scroll.js' },
  { name: 'jarallax', src: '../../assets/parallax/jarallax.min.js' },
  { name: 'jquery.formstyler', src: '../../assets/formstyler/jquery.formstyler.js' },
  { name: 'jquery.formstyler.min', src: '../../assets/formstyler/jquery.formstyler.min.js' },
  { name: 'jquery.datetimepicker.full', src: '../../assets/datepicker/jquery.datetimepicker.full.js' },
  { name: 'social-likes', src: '../../assets/sociallikes/social-likes.js' },
  { name: 'typed', src: '../../assets/typed/typed.min.js' },
  { name: 'nav-dropdown', src: '../../assets/dropdown/js/nav-dropdown.js' },
  { name: 'navbar-dropdown', src: '../../assets/dropdown/js/navbar-dropdown.js' },
  { name: 'jquery.touch-swipe', src: '../../assets/touchswipe/jquery.touch-swipe.min.js' },
  { name: 'theme', src: '../../assets/theme/js/script.js' },
  { name: 'formoid', src: '../../assets/formoid/formoid.min.js' }
];

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  public load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach(script => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  public loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        // Load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Other browsers
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

  public setScriptUnloaded(name: string) {
    if (this.scripts[name].loaded) {
      this.scripts[name].loaded = false;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Achievements } from './models/achievements.model';

import { DynamicScriptLoaderService } from './services/dynamic-script-loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public dataWord1 = 'Software Development';
  public dataWord2 = 'Web Development';
  public dataWord3 = 'Theta Healing ® Consulting';

  public achievements: Achievements = {
    clients: 8,
    projectsInProgress: 5,
    projectsCompleted: 3,
    thetaHealingConsultations: 38
  };

  public currentYear = new Date().getFullYear();

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private dynamicScriptLoader: DynamicScriptLoaderService) {
    // Setup i18n
    translate.addLangs(['hu', 'de', 'en']);
    // This language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('hu');
    // The lang to use, if the lang isn't available, it will use the current loader to get them
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/hu/) ? browserLang : (browserLang.match(/de/) ? browserLang : 'en'));

    // Subscribe to language change
    translate.onLangChange.subscribe(() => {
      this.setTrsanslatedTexts();

      // Reload typed script for initialisation
      this.dynamicScriptLoader.setScriptUnloaded('typed');
      this.dynamicScriptLoader.load(
        'typed',
        ).then(data => {
        // Script Loaded Successfully
      }).catch(error => console.log(error));
    });

    // Load achievements
    this.loadAchievements().subscribe(
      data => {
        this.achievements = data;
      },
      error => {
        this.achievements = {
          clients: 8,
          projectsInProgress: 5,
          projectsCompleted: 3,
          thetaHealingConsultations: 38
        } as Achievements;
      });
  }

  public ngOnInit() {
    this.setTrsanslatedTexts();

    this.loadScripts();
  }

  public setLanguage(lang: string): void {
    this.translate.use(lang);
  }

  private setTrsanslatedTexts(): void {
    this.dataWord1 = this.translate.instant('i18n.header.dataWord1');
    this.dataWord2 = this.translate.instant('i18n.header.dataWord2');
    this.dataWord3 = this.translate.instant('i18n.header.dataWord3');
  }

  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load(
      // Do no load jquery
      // 'jquery',
      'popper',
      'tether',
      'bootstrap',
      'nav-dropdown',
      'navbar-dropdown',
      'jquery.touch-swipe',
      'smooth-scroll',
      'jquery.viewportchecker',
      'typed',
      'jarallax',
      'jquery.formstyler',
      'jquery.formstyler.min',
      'jquery.datetimepicker.full',
      'social-likes',
      'theme',
      'formoid'
      ).then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }

  private loadAchievements(): Observable<any> {
    return this.http.get('../assets/achievements.json');
  }
}
